.component3container {
  background: linear-gradient(45deg, #14ac69 0%, #84d157 100%);
  padding: 96px 0;

  .headingWrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 46px;

    .heading3 {
      width: 60%;
      color: #ffffff;
      font-size: 48px;
      font-weight: 800;
      line-height: 1.3;
      font-style: normal;
      text-align: center;
      font-family: "Manrope-Bold";
    }

    @media screen and (max-width: 480px) {
      .heading3 {
        font-size: 26px;
        line-height: 30px;
      }
    }
  }

  .psCard {
    width: 80%;
    display: flex;
    margin: 0.5rem 0;
    padding: 32px 64px;
    border-radius: 17px;
    flex-direction: column;
    align-items: flex-start;
    cursor: pointer;
    transition: all 0.1s ease-in;

    &.active {
      background: rgba(64, 142, 47, 0.25);
      opacity: 1 !important;
    }

    &:hover {
      opacity: 0.7;
    }

    .head {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 30px;
      color: #ffffff;
      font-family: "Manrope-Medium";
    }

    .desc {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      font-family: "Manrope-Regular";
    }
  }

  @media screen and (max-width: 480px) {
    .psCard {
      padding: 16px 32px;

      .head {
        font-size: 18px;
        line-height: 22px;
        text-align: center;
      }

      .desc {
        font-size: 14px;
        line-height: 20px;
        text-align: center;
      }
    }
  }

  .detailsSection {
    .card1Details {
      display: flex;
      flex-wrap: wrap;

      .card1ImgWrapper {
        width: 50%;
        display: flex;
        aspect-ratio: 1 / 1;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        img {
          width: 70%;
          object-fit: cover;
          border-radius: 8px 8px 0 0;
        }

        span {
          background-color: white;
          width: 70%;
          padding: 10px;
          text-align: center;
          border-radius: 0 0 8px 8px;
          font-family: "Manrope-Medium";
        }
      }
    }

    .card2Details {
      .productListWrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .categoriesWrapper {
        width: 100%;
        display: flex;
        padding: 0.5rem;
        overflow-x: auto;

        /* width */
        &::-webkit-scrollbar {
          height: 7px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: rgba(241, 241, 241, 0.2);
          border-radius: 10px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: rgba(241, 241, 241, 0.6);
          border-radius: 10px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: rgba(241, 241, 241, 0.8);
        }

        .categoryItem {
          width: 90px;
          height: 110px;
          display: flex;
          padding: 16px;
          margin: 0 0.25rem;
          border-radius: 8px;
          align-items: center;
          flex-direction: column;
          background-color: #14ac69;
          justify-content: space-between;

          img {
            width: 40px;
          }

          p {
            margin: 0;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            font-family: "Manrope-Regular";
            color: white;
          }
        }
      }

      @media screen and (max-width: 480px) {
        .categoriesWrapper {
          width: 90%;
          overflow-x: auto;
        }
      }
    }

    .card3Details {
      height: 100%;
      padding: 48px 0;
      .servicesWrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        align-items: space-between;

        .serviceItem {
          background-color: #fff;
          padding: 20px;
          box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.04),
            -8px 0px 24px rgba(0, 0, 0, 0.04);
          border-radius: 20px;
          display: flex;
          margin: 10px 0;

          img {
            object-fit: contain;
            border-radius: 10px;
          }
          .card3CardContent {
            margin-left: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            p.heading {
              font-family: "Manrope-SemiBold";
              font-style: normal;
              font-weight: 500;
              font-size: 20px;
              line-height: 16px;
              color: #000000;
            }
            p.desc {
              font-family: "Manrope-Regular";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 27px;
              color: #9e9e9e;
            }
          }
        }
      }
    }
  }
}
