.landingContainer {
  height: 85vh;
  width: 100%;
  max-height: 100vh;
  background-image: radial-gradient(
    ellipse at bottom right,
    #84d157,
    transparent 70%
  );

  .landingRow {
    justify-content: space-between;
    width: 100%;
    height: 100%;

    .imageWrapper {
      height: auto;

      .imgContentArea {
        & > img {
          width: 100%;
          transform: rotate(-45deg) scale(0.7);
          opacity: 0;
          animation-name: rotate_grow;
          animation-duration: 1s;
          animation-fill-mode: forwards;
          animation-timing-function: ease-in-out;
        }
      }
    }
  }

  @keyframes rotate_grow {
    from {
      transform: rotate(-45deg) scale(0.7);
      opacity: 0;
    }
    to {
      transform: rotate(0deg) scale(1);
      opacity: 1;
    }
  }

  @media screen and (max-width: 480px) {
    .landingRow {
      margin: 0;
      justify-content: flex-start;
      align-items: flex-start;

      .contextWrapper {
        flex: 0 1 auto;
      }
      .imageWrapper {
        flex: 1 1 auto;
        height: auto;

        .imgContentArea {
          display: flex;
          justify-content: center;

          & > img {
            height: 100%;
            width: 50%;
            object-fit: contain;
          }
        }
      }
    }
  }

  .textContentArea {
    display: flex;
    justify-content: center;
    height: 100%;
    flex-direction: column;

    & > p {
      font-family: "Manrope-ExtraBold";
      font-style: normal;
      font-weight: 600;
      font-size: 60px;
      line-height: 72px;
      letter-spacing: -0.02em;
      color: #101828;
    }

    .description {
      margin-bottom: 16px;

      & > p {
        font-family: "Manrope-ExtraBold";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #667085;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .textContentArea {
      justify-content: flex-start;
      height: min-content;
      align-items: center;

      .pill {
        margin-bottom: 5px;
        & > span {
          font-size: 10px;
          line-height: 10px;
        }
      }
      & > p {
        font-size: 34px;
        line-height: 35px;
        text-align: center;
      }
      .description {
        margin-bottom: 8px;

        & > p {
          font-size: 12px;
          line-height: 14px;
          text-align: center;
        }
      }
    }
  }
}
