.component4Container {
  padding: 96px 0;

  .heroTextWrapper {
    display: flex;
    justify-content: center;
    height: 100%;
    flex-direction: column;

    .heroHext {
      font-family: "Manrope-Bold";
      font-style: normal;
      font-weight: 600;
      font-size: 48px;
      line-height: 60px;
      letter-spacing: -0.02em;
      margin-bottom: 42px;
    }

    @media screen and (max-width: 480px) {
      .heroHext {
        font-size: 24px;
        line-height: 30px;
      }
    }
  }
}
