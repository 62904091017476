.contactUsWrapper {
  background-color: #1d2939;

  @media screen and (max-width: 480px) {
    .logoWrapper {
      display: flex;
      justify-content: center;
    }
  }

  .contactDetailsWrapper {
    border-bottom: 1px solid #475467;
    padding: 50px 0;

    .hyperlinks {
      display: flex;
      margin-top: 20px;

      a {
        font-size: 16px;
        text-decoration: none;
        color: #fff;
        font-family: "Manrope-Regular";
        margin-right: 1rem;

        &:hover {
          color: #7db840;
        }
      }
    }

    @media screen and (max-width: 480px) {
      .hyperlinks {
        justify-content: space-around;

        a {
          font-size: 12px;
          text-align: center;
          margin: 0;
        }
      }
    }

    .googleIconWrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;

      p {
        color: #fff;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        font-family: "Manrope-Regular";
      }
    }
    @media screen and (max-width: 480px) {
      .googleIconWrapper {
        justify-content: center;
        align-items: center;

        p {
          font-size: 14px;
        }
      }
    }
  }

  footer {
    padding: 25px 0;
    display: flex;
    justify-content: space-between;

    p {
      font-family: "Manrope-Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #98a2b3;
    }

    .socialWrapper {
      display: flex;

      a {
        margin: 0.5rem;

        img {
          width: 25px;
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    footer {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;

      p {
        text-align: center;
      }
    }
  }
}
