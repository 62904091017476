@font-face {
  font-family: "Manrope-Bold";
  src: local("Manrope-Bold"),
    url("./assets/fonts/Manrope/Manrope-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Manrope-ExtraBold";
  src: local("Manrope-ExtraBold"),
    url("./assets/fonts/Manrope/Manrope-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Manrope-Medium";
  src: local("Manrope-Medium"),
    url("./assets/fonts/Manrope/Manrope-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Manrope-SemiBold";
  src: local("Manrope-SemiBold"),
    url("./assets/fonts/Manrope/Manrope-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Manrope-Regular";
  src: local("Manrope-Regular"),
    url("./assets/fonts/Manrope/Manrope-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Inter-Medium";
  src: local("Inter-Medium"),
    url("./assets/fonts/Inter/Inter-Medium.ttf") format("truetype");
}
