.navigationContainer {
  padding: 1rem 0;
  display: flex;
  position: relative;

  .logoWrapper {
    display: flex;
    justify-content: flex-start;

    img {
      width: 100px;
      height: auto;
    }
  }

  .tabsContainer {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;

    .tab {
      margin: 0.5rem 1rem;
      text-decoration: none;

      & > p {
        font-family: "Manrope-SemiBold";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #667085;

        &:hover {
          color: #7db840;
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .tabsContainer {
      position: relative;

      .tab {
        & > p {
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
    .logoWrapper {
      justify-content: center;
    }
  }
}
