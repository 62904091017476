.component2Container {
  position: relative;
  padding-top: 3rem;

  .vectorbg {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  .sceneImg {
    margin-bottom: 96px;
  }

  .superscript {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #418e2f;
    font-style: normal;
    text-transform: uppercase;
    font-family: "Manrope-Bold";
  }

  .mainheadingWrapper {
    display: flex;
    justify-content: center;

    .mainheading {
      color: #101828;
      width: 58%;
      font-size: 48px;
      font-weight: 800;
      line-height: 1.3;
      font-style: normal;
      text-align: center;
      font-family: "Manrope-Bold";
    }
  }

  .subscriptWrapper {
    display: flex;
    margin-bottom: 48px;
    justify-content: center;

    .subscript {
      width: 80%;
      font-size: 18px;
      font-weight: 400;
      color: #667085;
      line-height: 30px;
      text-align: center;
      font-style: normal;
      font-family: "Manrope-Medium";
    }
  }

  @media screen and (max-width: 480px) {
    .superscript {
      font-size: 12px;
      line-height: 16px;
    }
    .mainheadingWrapper {
      .mainheading {
        font-size: 24px;
        line-height: 25px;
      }
    }
    .subscriptWrapper {
      .subscript {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
