.textGradiant {
  background: -webkit-linear-gradient(#14ac69, #84d157);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

body,
html {
  font-family: "Manrope-Regular";
}
